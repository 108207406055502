.ListItem {
    position: relative;
}

.SubNav {
    width     : max-content;
    max-height: 0;
    overflow  : hidden;
    padding   : 0px;

    display       : flex;
    flex-direction: column;

    position: absolute;
    top     : 30px;
    left    : 0;
    z-index : 1;

    background: #F4FAF4;
    box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.05);
}

.SubNav.Open {
    max-height: 50vh;
    padding   : 12px;
    min-width : 100%;

    transition: max-height 1s ease-in-out;
}

.DownButton {
    margin-left: 10px;

    border      : solid rgba(27, 94, 32, 0.25);
    border-width: 0 1.5px 1.5px 0;
    display     : inline-block;
    padding     : 3px;

    transform        : rotate(45deg);
    -webkit-transform: rotate(45deg);

    margin-bottom: 3px;
}

.SubNav a:hover {
    text-decoration: underline;
}