.MarkContainer {
    color: #1B5E20;
}

.MarkContainer>* {
    margin-bottom: 40px;
    margin-top   : 40px;
}

.MarkContainer>*:last-child {
    margin-bottom: 0;
}

.MarkContainer p:has(>img) {
    margin: 80px 0;
}

.MarkContainer li,
.MarkContainer h2,
.MarkContainer h3,
.MarkContainer h4,
.MarkContainer p {
    margin-bottom: 20px;
}

.MarkContainer ul:has(> li > img) {
    margin-bottom: 40px;
    margin-top   : 40px;
}

.MarkContainer p:has(~ h2),
.MarkContainer p:has(~ h3),
.MarkContainer p:has(~ p>a) {
    margin-bottom: 40px;
}

/* Big images */

.MarkContainer p:has(>img) {
    display        : flex;
    justify-content: center;
}


.MarkContainer p>img {
    width     : 70%;
    max-height: 430px;
    object-fit: cover;

    margin: 0 auto;

    border-radius: 8px;
}

/* Small images added inside list */

.MarkContainer li>img {
    max-height: 70px;
    width     : auto;
    object-fit: cover;
    max-width : 100%;
}

.MarkContainer li:has(> img)::before {
    content: none;
}

.MarkContainer ul:has(> li > img) {
    display        : flex;
    justify-content: center;
    align-items    : flex-start;
    flex-wrap      : wrap;
}

.MarkContainer ul:has(> li > img)>li {
    display  : inline-block;
    max-width: 30%;

    margin-right: 20px;

}

.MarkContainer h1 {
    font-size  : 24px;
    font-weight: 600;
    line-height: 120%;
    text-align : left;
}

.MarkContainer h2,
.MarkContainer h3 {
    font-size  : 24px;
    font-weight: 600;
    line-height: 29px;
    text-align : left;
}


.MarkContainer h4 {
    font-size  : 24px;
    font-weight: 400;
    line-height: 120%;
}

.MarkContainer p,
.MarkContainer li {
    font-size  : 16px;
    font-weight: 400;
    line-height: 120%;
    text-align : left;
}


.MarkContainer ul {
    list-style-position: inside;
    list-style-type    : none;
}

.MarkContainer li::before {
    content     : "•";
    margin-right: 10px;
}

.MarkContainer a {
    width: 100%;
}

.MarkContainer a:hover {
    text-decoration: underline;
}

.MarkContainer p:has(> a):hover {
    background-color: #2a7b2f;
}

.MarkContainer a {
    /* font-size  : inherit;
    font-weight: inherit;
    line-height: inherit; */

    /* text-align : center; */

    /* margin-right: 20px; */

    color: white;

}

.MarkContainer a:has(~ hr),
.MarkContainer p:has(> a)::after {
    font-size  : inherit;
    font-weight: inherit;
    line-height: inherit;

    text-align: left;

}


.MarkContainer p:has(> a) {
    width        : fit-content;
    height       : 100px;
    padding      : 35px;
    border-radius: 2.5px;

    display: inline-block;

    background-color: #1B5E20;

    display        : flex;
    align-items    : center;
    justify-content: flex-start;

    margin-left : auto;
    margin-right: auto;

    position: relative;

    font-size  : 24px;
    line-height: 120%;
    font-weight: 400;
}


.MarkContainer p:has(~ hr):has(> a) {
    padding: 20px;

    height: 50px;

    font-size  : 12px;
    line-height: 120%;
    font-weight: 600;
}

.MarkContainer p:has(> a)::after {
    content : url("../../../../assets/arrow_right.svg");
    position: absolute;
    right   : 20px;
}

.MarkContainer hr {
    width : 100px;
    height: 1px;

    background-color: #1B5E2040;

    margin: 40px auto;
}


@media(min-height: 850px) {
    .MarkContainer {
        min-height: 50vh;
    }

}

@media(max-width: 1000px) {
    .MarkContainer>*:last-child {
        margin-bottom: 40px;
    }


    .MarkContainer p:has(>img) {
        margin: 40px 0;
    }


    .MarkContainer p>img {
        width: 100%;
    }


    .MarkContainer h1,
    .MarkContainer h2,
    .MarkContainer h3,
    .MarkContainer h4,
    .MarkContainer p:has(> a) {
        font-size: 20px;
    }

    .MarkContainer p:has(> a) {
        height: 75px;
    }

}