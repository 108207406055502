.Navbar {
    display        : flex;
    justify-content: space-between;
    align-items    : center;

    padding: 15px 20px;

    background: #F4FAF4;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);

    position: fixed;
    top     : 0;
    left    : 0;
    z-index : 50;
    width   : 100%;
}

.Icon {
    cursor: pointer;
}

.Logo {
    height: 48px;

    display    : flex;
    align-items: center;
}

.Logo>img {
    height: 100%;

    margin-right: 10px;
}

.Logo>span {
    font-family: 'Philosopher';
    font-style : normal;
    font-weight: 700;
    font-size  : 26px;
    line-height: 29px;

    color: #1B5E20;
}