.Wrapper {
    background-color: #E8F5E9;

    padding: 80px 40px;
}

.Container {
    max-width: 1080px;
    margin   : 0 auto;

    font-family: 'Montserrat';
    font-style : normal;

    color: #1E1E1E;
}

.Header {
    font-weight: 400;
    font-size  : 24px;
    line-height: 29px;

    margin-bottom: 40px;
}

.Item {
    display: flex;

    font-weight: 400;
    font-size  : 14px;
    line-height: 40px;

    color: #1B5E20;

    padding-bottom: 20px;
    padding-top   : 20px;

    border-bottom: 1px solid rgba(27, 94, 32, 0.2);
}

.Item>p:first-of-type {
    font-weight: 600;

    padding-right: 10px;
}

.Item>* {
    flex: 1 1 0;
}