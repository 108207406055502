.Message {
    font-size  : 14px;
    line-height: 17px;
    font-weight: normal;

    color: #1B5E20;

    text-align: center;

    margin: 40px 10%;
    width : 80%;
}