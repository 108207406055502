.button-simple {
    border-radius: 100px;

    color           : #E8F5E9;
    background-color: #1B5E20;

    padding: 10px 15px 10px 15px;

    font-size  : 14px;
    line-height: 120%;

    width: fit-content;
}

.button-simple:hover {
    background-color: #418946;

    text-decoration: none !important;
}

.center {
    text-align: center;
}

.no-wrap {
    text-wrap: nowrap;
}

.break-space {
    white-space-collapse: break-spaces;
}

.color-green {
    color: #1B5E20 !important;
}

.color-green-lt {
    color: #2C9A34 !important;
}

.color-green-lt2 {
    color: #35B83E !important;
}

div.flex {
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.start-row {
    justify-content: flex-start;
}

.center-row {
    align-items: center;
}

.start-col {
    align-items: flex-start !important;
}

.all-flex-space {
    flex: 1;
}

.big-header {
    font-size: 48px !important;
}

.big-text-2 {
    font-size: 36px !important;
}

.big-text {
    font-size: 24px !important;
}

.med-text {
    font-size: 18px !important;
}

.bold {
    font-weight: bold !important;
}

.underlined {
    text-decoration: underline !important;
}

.small-header {
    font-size: 16px !important;
}

.smaller {
    font-size: 21px !important;
}

.small-text {
    font-size: 14px !important;
}

.small-text-container p {
    font-size: 14px !important;
}

a.light {
    background-color: transparent;

    color: #1B5E20;

    border: 2px solid #1B5E20;

    padding: 20px 40px 20px 20px;

    width: fit-content;

    border-radius: 5px;

    font-weight: bold;
}

a.arrow-right {
    position: relative;
}

a.arrow-right::after {
    content : url("../../../../assets/arrow_right.svg");
    position: absolute;
    right   : 20px;
}

a.big {
    padding: 35px 55px 35px 40px;

    font-size: 20px;
}

a.big.arrow-right::after {
    scale: 1.5;
}

a.light.arrow-right::after {
    color: #1B5E20;
}

.white-space {
    width     : inherit;
    text-align: center;
}

.flex-column {
    display       : flex;
    flex-direction: column;
}

.flex-column.space {
    justify-content: space-between;
}

.flex-column>* {
    margin-bottom: 40px;
}

.flex-column.margin-20>* {
    margin-bottom: 20px;
}

.flex-row {
    display       : flex;
    flex-direction: row;

    gap: 20px;
}

.flex-up-down>*:nth-child(odd) {
    align-self: flex-start;
}

.flex-up-down>*:nth-child(even) {
    align-self: flex-end;
}

.gap-40 {
    gap: 40px;
}

.gap-10 {
    gap: 10px;
}

.gap-15 {
    gap: 15px;
}

.flex-end {
    display        : flex;
    justify-content: flex-end;
}

.flex-center {
    justify-content: center;
    align-items    : center;
}

.flex-start {
    display        : flex;
    align-items    : flex-start;
    justify-content: flex-start;
}

.flex-start>* {
    text-align: left;
}

.flex-row>* {
    flex: 1;
}

.flex>*>img {
    width     : 100% !important;
    height    : 100% !important;
    object-fit: cover !important;
}

.grid {
    display: grid;
}

.grid.row {
    grid-auto-rows: 1fr;
    row-gap       : 40px;
}

.grid-col-2 {
    grid-template-columns: 1fr 1fr;
    column-gap           : 40px;
}

.full-width {
    width: 100%;
}

.children-50>*>* {
    width: 50%;
}

.green-bgcolors {
    color: white;
}

.green-bgcolors>*:nth-child(1) {
    background-color: #1B5E20;
}


.green-bgcolors>*:nth-child(2) {
    background-color: #237C2A;
}

.green-bgcolors>*:nth-child(3) {
    background-color: #2C9A34;
}

.green-bgcolors hr {
    background-color: #FFFFFF40;
}

.pad-10 {
    padding: 10px;
}

.pad-20 {
    padding: 20px;
}

.pad-40 {
    padding: 40px;
}

.margin-t-0 {
    margin-top: 0px !important;
}

.margin-tb-0 {
    margin-top   : 0px !important;
    margin-bottom: 0px !important;
}

.margin-tb-20 {
    margin-top   : 20px !important;
    margin-bottom: 20px !important;
}

.margin-tb-40 {
    margin-top   : 40px !important;
    margin-bottom: 40px !important;
}

.margin-b-80 {
    margin-bottom: 80px !important;
}

.margin-t-80 {
    margin-top: 80px !important;
}

.container-margin-20>* {
    margin-bottom: 20px;
}

.container-margin-20>*:last-child {
    margin-bottom: 0;
}

.container-margin-20 hr {
    margin-top   : 0px;
    margin-bottom: 20px;
}

.flex-start hr {
    margin-left : 0;
    margin-right: 0;
}

.rad-5 {
    border-radius: 5px;
}

.bg-dark {
    position: relative;

    background-color: #D7EDD9;
}

.bg-dark::before {
    content: "";
    height : 100%;
    width  : 100%;

    position: absolute;
    top     : 0;
    right   : 100%;

    background-color: #D7EDD9;
}

.bg-dark::after {
    content: "";
    height : 100%;
    width  : 100%;

    position: absolute;
    top     : 0;
    left    : 100%;

    background-color: #D7EDD9;
}

.bg-light {
    position: relative;

    background-color: #F4FAF4;
}

.bg-light::before {
    content: "";
    height : 100%;
    width  : 100%;

    position: absolute;
    top     : 0;
    right   : 100%;

    background-color: #F4FAF4;
}

.bg-light::after {
    content: "";
    height : 100%;
    width  : 100%;

    position: absolute;
    top     : 0;
    left    : 100%;

    background-color: #F4FAF4;
}

.pad-tb-20 {
    padding-top   : 20px;
    padding-bottom: 20px;
}

.pad-tb-40 {
    padding-top   : 40px;
    padding-bottom: 40px;
}

.pad-tb-80 {
    padding-top   : 80px !important;
    padding-bottom: 80px !important;
}

.pad-t-80 {
    padding-top: 80px !important;
}

.pad-b-40 {
    padding-bottom: 40px !important;
}


/* About Us specific classes*/
div.mission {
    display    : flex;
    align-items: center;
}

div.mission>img {
    width : 30%;
    height: auto;
}

.full-img {
    mix-blend-mode: multiply;

    width       : 88%;
    margin-left : 6%;
    margin-right: 6%;
}

blockquote {
    background-color: #C6E6C9;
    color           : #1B5E20;

    padding   : 40px 20px;
    text-align: center;
}

.blockquote {
    background-color: #C6E6C9;
    color           : #1B5E20;

    padding   : 40px 0;
    text-align: center;

    position: relative;
}

.blockquote::before {
    content: "";
    height : 100%;
    width  : 100%;

    position: absolute;
    top     : 0;
    right   : 100%;

    background-color: #C6E6C9;
}

.blockquote::after {
    content: "";
    height : 100%;
    width  : 100%;

    position: absolute;
    top     : 0;
    left    : 100%;

    background-color: #C6E6C9;
}

.credit {
    display       : flex;
    flex-direction: column;
    align-items   : center;
}

.credit>img {
    max-width: 132px;
    height   : auto;
}

.no-end-padding {
    margin-bottom: -80px !important;
}


@media(max-width: 900px) {
    div.mission {
        flex-direction: column;
    }

    div.mission>img {
        width     : 80%;
        height    : auto;
        max-height: 30vh;

        object-fit: contain;
    }

    .full-img {
        width       : 100%;
        margin-left : 0;
        margin-right: 0;
    }

    .no-end-padding {
        margin-bottom: -40px !important;
    }
}

/* About Us specific classes ends..*/

/* Projects specific classes */
.base-items {
    display: flex;
}

.base-items>* {
    display    : flex;
    align-items: flex-start;

    border       : 2px solid #5A8F5D;
    border-radius: 5px;

    padding: 40px;
    width  : 50%;

    margin-right: 20px;

    background-color: #F4FAF4BF;
}

.base-items>*:last-child {
    margin-right: 0;
}

.base-items>*>img {
    margin-right: 20px;

    width : 48px;
    height: 48px;
}

.base-items h4 {
    margin-bottom: 0;

    padding: 15px 0;

    color: #1B5E20;
}

.base-items p {
    /* color: #1E1E1E; */
    color: #1B5E20;
}

.center {
    text-align: center !important;
}

.center>* {
    text-align: center !important;
}

.main-items {
    display: flex;
    width  : 100%;

    margin-top: 40px;
}

.main-items>* {
    width: calc((100% - 40px) / 3);

    margin-right: 20px;

    background-color: #1B5E20;

    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;

    padding: 40px;

    border-radius: 5px;
}

.main-items.green-bgcolors>*:nth-child(2) {
    background-color: #237C2A;
}

.main-items.green-bgcolors>*:nth-child(3) {
    background-color: #2C9A34;
}

.main-items h4 {
    color: white !important;
}

.main-items>*:last-child {
    margin-right: 0;
}

.main-items img:first-of-type {
    width : 48px;
    height: 48px;
}

.main-items>*>* {
    margin-bottom: 20px;
}

.main-items>*>*:last-child {
    margin-bottom: 0;
}

.list-items {
    background-color: #F4FAF4;

    display    : flex;
    align-items: center;

    position: relative;

    padding-top   : 80px;
    padding-bottom: 80px;
}

.list-items>* {
    width: calc(50% - 20px);

    margin-right: 40px;
}

.list-items>*:last-child {
    margin-right: 0;
}

.list-items::before {
    content: "";
    height : 100%;
    width  : 100%;

    position: absolute;
    top     : 0;
    right   : 100%;

    background-color: #F4FAF4;

}

.list-items::after {
    content: "";
    height : 100%;
    width  : 100%;

    position: absolute;
    top     : 0;
    left    : 100%;

    background-color: #F4FAF4;

}

.list-items img {
    height: 96px;

    margin-bottom: 40px;
}

.list-items ul li {
    list-style-image   : url("../../../../assets/list-icon.svg");
    list-style-position: outside;

    padding-left: 12px;

    color: #1B5E20;
}

.list-items ul li::before {
    content: unset;
}

.green-list li {
    position: relative;

    padding-left: 35px;
}

.green-list li::before {
    content       : "\25CF";
    font-weight   : bold;
    display       : inline-block;
    /* width      : 1em; */
    font-size     : 26px;

    color: #91DF97;

    position: absolute;
    top     : calc(50% - 11px);
    left    : 0;
}

.green-list.bullet-start li::before {
    top: 0;
}

.green-list li:nth-of-type(1):before {
    color: #1B5E20;
}

.green-list li:nth-of-type(2)::before {
    color: #237C2A;
}

.green-list li:nth-of-type(3)::before {
    color: #2C9A34;
}

.green-list li:nth-of-type(4)::before {
    color: #35B83E;
}

.green-list li:nth-of-type(5)::before {
    color: #5BCF64;
}

.green-list li:nth-of-type(6)::before {
    color: #6DD475;
}

.green-list li:nth-of-type(7)::before {
    color: #7FD986;
}

.green-list li:nth-of-type(8)::before {
    color: #91DF97;
}

@media(max-width: 900px) {
    .base-items {
        flex-direction: column;
    }

    .base-items>* {
        width: 100%;

        margin-right : 0;
        margin-bottom: 20px;

        padding: 20px;
    }

    .main-items {
        flex-wrap: wrap;
    }

    .main-items>* {
        padding: 20px 10px;
    }


    .list-items {
        flex-direction: column;
        align-items   : center;
        text-align    : center;

        padding-top   : 40px;
        padding-bottom: 40px;
    }

    .list-items h4 {
        text-align: center !important;
    }

    .list-items>* {
        width: 100%;

        margin-right : 0;
        margin-bottom: 20px;
    }

    .list-items ul {
        width: calc(100% - 40px);
    }

    .list-items li {
        padding-left: 5px !important;
    }

    .grid-col-2 {
        grid-template-columns: 1fr;
        grid-template-rows   : 1fr 1fr;
    }

    .flex-up-down {
        height    : unset !important;
        max-height: unset !important;
    }

    .flex-up-down>* {
        margin-bottom: 0;
        gap          : 0;
    }
}

@media(max-width: 600px) {
    .main-items>* {
        padding: 20px 10px;

        width: 100%;

        flex-direction : row;
        align-items    : center;
        justify-content: space-between;

        margin-right : 0;
        margin-bottom: 20px;

        padding: 20px;
    }

    .main-items>*>* {
        margin-bottom: 0 !important;
        margin-right : 20px;
    }

}

@media(max-width: 900px) {
    .break-space {
        white-space-collapse: unset;
    }

    .grid.row {
        grid-auto-rows: auto;
        row-gap       : 40px;
    }

    .children-50>*>* {
        width: 100%;
    }

    .pad-40 {
        padding: 20px;
    }

    .pad-20 {
        padding: 10px;
    }

    .flex,
    .flex-start,
    .flex-row {
        flex-direction: column;
        align-items   : center;
    }

    .no-col {
        flex-direction: row;
    }

    .flex-row h4 {
        min-height: unset !important;
    }

    .flex>* {
        margin-bottom: 10px;
    }

    .flex-row:has(>ul) {
        align-items: flex-start;
    }

    .flex-start img,
    .flex img,
    .flex-end img {
        max-width: 100%;
    }

    /* .flex-start {
        align-items: center;
    } */

    .flex-center {}

    .flex-row>* {
        width: 100%;
    }

    .flex-end {
        justify-content: center;
    }

    .big-text-2 {
        font-size: 22px !important;
    }

    .big-text {
        font-size: 18px !important;
    }

    .gap-40 {
        gap: 20px;
    }

    .flex-up-down {
        height    : unset !important;
        max-height: unset !important;
    }

    .flex-up-down>* {
        margin-bottom: 0;
        gap          : 0;
    }

    .big-header {
        font-size: 22px !important;
    }

    .no-col>*.pad-10 {
        padding: 0;
    }

}