.MainNav {
    display: flex;

    align-items: center;

    margin-right: 20px;
}

.Logo {
    height: 48px;

    display    : flex;
    align-items: center;

    margin-right: 40px;
}

.Logo a {
    display: inline-block;
    height : 48px;
}

.Logo img {
    height: 100%;

    margin-right: 10px;
}

.Logo span {
    display: inline-block;

    font-family: 'Philosopher';
    font-style : normal;
    font-weight: 700;
    font-size  : 26px;
    line-height: 29px;

    color: #1B5E20;
}

.Button {
    padding-left : 15px;
    padding-right: 15px;

    background   : #1B5E20;
    border-radius: 100px;
}

.Button a {
    color: #E8F5E9;
}

.Button a:hover {
    text-decoration: none;
}

.Button:hover {
    background: #479c4d;
}