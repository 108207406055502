.Mask {
    -webkit-mask       : url("../../../../assets/mask.png") center center / cover;
    mask               : url("../../../../assets/mask.png") center center / cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat        : no-repeat;

    mask-size: 100% 100%;

    width : 100%;
    height: 100%;
}

@media(max-width: 950px) {
    .Mask {
        width : calc(100vw - 80px);
        height: calc((100vw - 80px) / 0.86);

        max-width: 400px;
        max-height: calc(400px / 0.86);
    }
}