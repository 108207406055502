.Wrapper {
    background-color: #F4FAF4;

    padding: 80px 40px;
}

.Container {
    max-width: 1080px;
    margin   : 0 auto;

    font-family: 'Montserrat';
    font-style : normal;
    color      : #1E1E1E;
}

.Header {
    font-weight: 400;
    font-size  : 24px;
    line-height: 29px;

    margin-bottom: 40px;
}

.Item {
    display: flex;

    padding-bottom: 40px;
}

.ItemContainer>div {
    margin-bottom: 40px;
}

.ItemContainer>div:last-of-type {
    margin-bottom: 0;
}

.Item>* {
    flex: 1 1 0;
}

.ItemHeader {
    font-weight: 600;
    font-size  : 32px;
    line-height: 40px;

    text-align: right;

    color: #1B5E20;

    margin-right: 40px;
}

.TextContainer {
    font-weight: 400;
    font-size  : 14px;
    line-height: 20px;
}

.ImgContainer {
    margin-top: 20px;

    display: flex;
}

.Img {
    height    : 80px;
    width     : 80px;
    object-fit: cover;

    margin-right: 10px;

    border-radius: 10px;
}

@media(max-width: 900px) {
    .Item {
        flex-direction: column;
    }

    .ItemHeader {
        text-align: center;

        margin-right : 0;
        margin-bottom: 20px;
    }

    .ImgContainer {
        justify-content: center;
    }
}