.Item {
    height: 831px;
    width : 100%;

    display    : flex;
    align-items: center;
}

.Item>* {
    flex: 1 1 0;
}

.TextItem {
    display        : flex;
    justify-content: end;
    flex-direction : column;
    align-items    : end;

    height: 100%;
}

.TextItem>* {
    /* flex: 1 1 0; */
}

.TextContent {
    display        : flex;
    flex-direction : column;
    justify-content: end;
    align-items    : start;

    width: 85%;
}

.Placeholder {
    width: 100%;

    height: 35%;
}

.TextContent>* {
    margin-bottom: 40px;
}

.ImgItem {
    display        : flex;
    justify-content: center;

    height    : 75%;
    max-height: 600px;
}

.ImgContent {
    width    : 90%;
    max-width: 520px;

    pointer-events: none;
}

.ImgContent img {
    height    : inherit;
    width     : 100%;
    object-fit: cover;
}

.Header,
.Text {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 300;
    font-size  : 32px;
    line-height: 39px;
    display    : flex;
    align-items: center;

    color: #1B5E20;
}

.Text {
    font-size  : 22px;
    line-height: 39px;
}

.Button {
    display: inline-block;

    background   : #1B5E20;
    border-radius: 100px;

    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 600;
    font-size  : 13px;
    line-height: 16px;

    color: #E8F5E9;

    padding: 10px 15px;

    margin-bottom: 0;
}


@media(max-width: 950px) {
    .Item {
        flex-direction: column-reverse;

        height: unset;
    }

    .Item>* {
        flex: unset;
    }

    .TextItem {
        justify-content: center;
        align-items    : center;

        height: unset;
    }

    .TextContent {
        width: 100%;

        align-items: center;
    }

    .Header {
        font-size  : 24px;
        line-height: 29px;
    }

    .Placeholder {
        display: none;
    }

    .ImgContent {
        width: 100%;
    }

    .ImgItem {
        height: unset;

        display: block;

        margin-bottom: 15px;
    }

}