.Nav {
    padding   : 15px 20px;
    min-height: 78px;

    display        : flex;
    align-items    : center;
    justify-content: space-between;
    flex-wrap      : wrap;

    background: #F4FAF4;

    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}

.Nav ul {
    list-style: none;

    display: flex;
}

.Nav ul>li {
    margin-right: 20px;
}

.Nav ul>li:last-of-type {
    margin-right: 0;
}


.ActiveLink,
.Link {
    display: inline-block;

    font-family: 'Montserrat';
    font-style : normal;
    font-size  : 14px;
    line-height: 17px;

    color: #1B5E20;

    padding: 10px 0;

    margin-top: 2px;
}

.Link {
    font-weight: 400;
}

.ActiveLink {
    font-weight: 600;
}

.Link:hover {
    text-decoration: underline;
}