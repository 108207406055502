.Item {
    text-align: left;

    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;

    color: #F4FAF4;

    padding: 40px;
}

.HeaderContainer {
    display: flex;

    margin       : 0;
    margin-bottom: 20px;
}

.HeaderContainer img {
    display: block;

    margin-right: 20px;

    width        : 100px;
    height       : 100px;
    object-fit   : cover;
    border-radius: 10px;

    border: solid 1px #fff;
}

.Header,
.Header>* {
    font-size  : 24px;
    line-height: 29px;

    min-height        : calc(29px * 3);
    display           : -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow          : hidden;

    text-overflow: ellipsis;
}


.Text {
    font-size  : 14px;
    line-height: 17px;

    margin       : 0;
    margin-bottom: 20px;

    min-height        : 85px;
    display           : -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow          : hidden;

    text-overflow: ellipsis;
}

.Button {
    display: inline-block;

    background   : rgba(244, 250, 244, 0.5);
    border-radius: 100px;

    font-weight   : 700;
    font-size     : 10px;
    line-height   : 12px;
    letter-spacing: 0.1em;

    padding: 10px;

    text-decoration: none;
    color          : inherit;
}

@media(max-width: 750px) {

    .Header,
    .Header>* {
        min-height: unset;
        display   : block;

        max-height        : calc(29px * 4);
        -webkit-line-clamp: 4;
    }

    .Text {
        min-height        : calc(4 * 17px);
        -webkit-line-clamp: 4;
    }

}

@media(max-width: 450px) {
    .Item {
        padding: 0 10px;
    }

    .HeaderContainer {
        display       : flex;
        flex-direction: column;
        align-items   : center;
    }

    .HeaderContainer img {
        margin-bottom: 20px;
        margin-right : 0px;

        width : 100%;
        height: unset;

        aspect-ratio: 1;
    }

    .Text {
        min-height        : calc(5 * 17px);
        -webkit-line-clamp: 5;
    }

    .Button {
        display   : block;
        text-align: center;
    }


}