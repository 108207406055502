.Item {
    padding: 10px;
}

.Container {
    display: flex;

    text-align: center;

    border-radius: 5px;

    background-color: rgba(244, 250, 244, 0.75);
}

.Selected.Container {
    background-color: unset;
}

.Container>* {
    flex: 1 1 0;
}

.Container * {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;

    color: #1E1E1E;
}

.Container img {
    width: 167px;
}

.ImgContainer {
    width : 167px;
    height: 150px;

    mask            : url("../../../assets/polygon.svg");
    mask-image      : url("../../../assets/polygon.svg");
    mask-size       : 100% 100%;
    background-color: #1B5E20;

    background-repeat  : no-repeat;
    background-size    : 100%;
    background-position: center;
}

.Person {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: flex-start;

    cursor: pointer;

    margin: 40px;

    max-height: 308px;
    min-height: 308px;
}

.Desc {
    /* display: none; */
    opacity: 0;

    font-size  : 14px;
    line-height: 20px;
    text-align : left;

    margin: 40px 40px 40px 0;
}

.Name {
    font-size  : 24px;
    line-height: 29px;

    margin: 10px 0;
}

.Title {
    font-size  : 14px;
    line-height: 17px;

    width: 95%;
}

.Link {
    display: inline-block;

    margin-top   : 10px;
    margin-bottom: 20px;
}

@media(max-width: 1185px) {
    .Person {
        margin: 20px;
    }
}


@media(max-width: 900px) {
    .Container {
        flex-direction: column;
        align-items   : center;
    }

    .Person {
        padding: 20px 0;

        margin: 40px 40px 0 40px;

        max-height: 268px;
        min-height: 268px;

    }

    .ImgContainer {
        width : 112px;
        height: 100px;
    }

    .Link {
        margin-bottom: 0;
    }

    .Desc {
        font-size  : 14px;
        line-height: 20px;
        text-align : left;

        margin: 0px 40px 10px 40px;

        overflow-y: auto;
        max-height: calc(100vh - 450px);
    }
}