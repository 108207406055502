.Container {
    background-color: #E8F5E9;
}

.SectionHeader {
    background: #1B5E20;

    padding: 20px 20px;

    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
    font-size  : 24px;
    line-height: 29px;

    color: #F4FAF4;
}

.SectionHeader>* {
    max-width   : 1360px;
    margin-left : auto;
    margin-right: auto;
}

.SectionHeader h1,
.SectionHeader h2,
.SectionHeader h3 {
    margin-top: 10px;

    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
    font-size  : 24px;
    line-height: 29px;

    color: #F4FAF4;
}

.SectionContent {
    margin        : 40px auto 0;
    padding-bottom: 80px;
    padding-left  : 40px;
    padding-right : 40px;

    /* max-width: 800px; */
    max-width: calc(367px * 3 + 80px);
}

@media(max-width: 900px) {
    .SectionContent {
        max-width     : unset;
        margin        : 40px 40px 0;
        padding       : 0;
        padding-bottom: 40px;

    }
}