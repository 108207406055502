.Container h1,
.Container h2,
.Container h3 {
    font-family   : 'Montserrat';
    font-style    : normal;
    /* font-weight: 400; */
}

.Container h1 {
    margin-bottom: 30px;

    font-size  : 28px;
    line-height: 1.2;
}

.Container h2 {
    margin-bottom: 30px;

    font-size  : 24px;
    line-height: 29px;
}

.Container h3 {
    margin-bottom: 20px;

    font-size  : 22px;
    line-height: 25px;
}

.Container p {
    margin-bottom: 15px;

    font-size  : 14px;
    line-height: 20px;
}

.Container table,
.Container th,
.Container td {
    border: 1px solid;
}

.Container table {
    border         : 1px solid;
    border-collapse: collapse;

    margin-bottom: 15px;
}

.Container th,
.Container td {
    padding: 10px;
}

.Container ul,
.Container ol {
    padding-left: 1em;

    margin-bottom: 15px;

}

.Container ul li::marker {
    content: " • ";
}

.Container li {
    margin-bottom: 10px;
}

.Container a {
    color      : #1B5E20;
    font-weight: bold;
}

.Container a:hover {
    text-decoration: underline;
}

.Container img {
    width        : 100%;
    /* max-height: 50vh; */

    aspect-ratio: 1000 / 563;

    object-fit: cover;

    border-radius: 10px;

    margin-bottom: 25px;
}