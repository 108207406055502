.Wrapper {
    background-color: #1B5E20;

    padding: 80px 98px;
}

.Container {
    max-width: 1485px;
    margin   : 0 auto;

    font-family: 'Montserrat';
    font-style : normal;
}

.Slider {
    display    : flex;
    align-items: center;

    position: relative;
}

.Header {
    font-weight: 400;
    font-size  : 24px;
    line-height: 29px;
    text-align : center;

    color: #F4FAF4;

    margin-bottom: 40px;
}

.Slider>svg {
    position: absolute;

    margin-bottom: 50px;

    cursor: pointer;
}

.Slider>svg:first-of-type {
    left: -40px;
}

.Slider>svg:last-of-type {
    right: -40px;
}

.Item {
    height: 250px;

    padding: 0 20px;
}

.Item>a,
.Item img {
    display: inline-block;

    width : 100%;
    height: 100%;
}

.Item img {
    object-fit: cover;

    pointer-events: none;

    border-radius: 10px;
}

.Dot {
    display: inline-block;

    height      : 3px;
    margin-right: 10px;

    border-radius: 100px;

    background: #5A8F5D;

    transition: width 0.3s ease-in-out;

    cursor: pointer;
}

.ActiveDot {
    width: 100px;
}

.InactiveDot {
    width: 20px;

    opacity: 0.25;
}

@media(max-width: 800px) {
    .Slider svg {
        display: none;
    }

    .Wrapper {
        padding-left : 20px;
        padding-right: 20px;
    }
}