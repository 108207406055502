.Container {
    display        : flex;
    align-items    : center;
    justify-content: flex-end;

    height: fit-content;
}

.Header {
    font-weight: 400;
    font-size  : 10px;
    line-height: 12px;

    margin-right: 30px;
}

.RightArrow {
    margin-left: 20px;

    border      : solid rgba(27, 94, 32, 0.25);
    border-width: 0 1.5px 1.5px 0;
    display     : inline-block;
    padding     : 4px;

    transform        : rotate(315deg);
    -webkit-transform: rotate(315deg);
}

.List {
    display    : flex;
    align-items: center;

    list-style: none;
}

.Link {
    font-weight: 600;
    font-size  : 10px;
    line-height: 12px;

    color: inherit;

    display    : flex;
    align-items: center;
}

.List img {
    margin-right: 10px;

    max-height: 22px;
}

.List>li {
    margin-right: 20px;
}

.List>li:last-of-type {
    margin-right: 0px;
}

.Link:hover {
    text-decoration: underline;
}

@media(max-width: 1050px) {
    .Container {
        /* flex-direction : column;
        justify-content: flex-start;
        align-items    : flex-start; */
    }

    .List {
        flex-direction : column;
        align-items    : flex-start;
        justify-content: flex-start;

        margin-top: 10px;
    }

    .List>* {
        margin-bottom: 10px;
        margin-right : 0;
        padding      : 10px 0;
    }
}