.Desktop {
    display: flex;
}

.Mobile {
    display: none;
}

.Content {
    margin-top: 0;
}

@media(max-width: 1100px) {
    .Desktop {
        display: none;
    }

    .Mobile {
        display: flex;
    }

    .Content {
        margin-top: 78px;
    }
}