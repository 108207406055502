.Container h1,
.Container h2,
.Container h3 {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
}

.Container h2 {
    margin-bottom: 30px;

    font-size  : 24px;
    line-height: 29px;
}

.Container h3 {
    margin-bottom: 20px;

    font-size  : 22px;
    line-height: 25px;
}

.Container table,
.Container th,
.Container td {
    border: 1px solid;
}

.Container table {
    border         : 1px solid;
    border-collapse: collapse;

    margin-bottom: 15px;
}

.Container th,
.Container td {
    padding: 10px;
}

.Container ul,
.Container ol {
    padding-left: 1em;
}

.Container ul li::marker {
    content: " • ";
}

.Container li {
    margin-bottom: 10px;
}

.Container p,
.Container ol,
.Container ul {
    margin-bottom: 15px;
}

.Container a {
    color      : #1B5E20;
    font-weight: bold;
}

.Container a:hover {
    text-decoration: underline;
}