.Container {
    background-color: #1B5E20;
    color           : white;

    display        : flex;
    justify-content: space-around;
    align-items    : center;

    height    : 700px;
    min-height: fit-content;

    background-repeat  : no-repeat;
    background-size    : cover;
    background-position: center;

    transition-property       : all;
    transition-duration       : 0.5s;
    transition-delay          : 0.2s;
    transition-timing-function: ease-in;

    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, .6);
}

.Content {
    width    : 85%;
    max-width: 827px;

}


.Dot {
    display: inline-block;

    height      : 5px;
    margin-right: 10px;

    border-radius: 100px;

    background: #FFFFFF;

    transition: width 0.3s ease-in-out;

    cursor: pointer;
}

.ActiveDot {
    width: 100px;
}

.InactiveDot {
    width: 20px;

    opacity: 0.25;
}

.PreloadImg {
    display : none;
    position: fixed;
    right   : -10px;
    top     : -20px;
}

@media(max-width: 600px) {
    .Container {
        padding: 30px 10px;
        height : fit-content;
    }
}

@media(max-width: 500px) {
    .Container {
        height    : 70vh;
        min-height: fit-content;
    }
}