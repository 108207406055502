.Section {
    /* padding: 115px 0; */

    background: #E8F5E9;

    --main_m1: 900px;
}

.Dot {
    display: inline-block;

    height      : 3px;
    margin-right: 10px;

    border-radius: 100px;

    background: #5A8F5D;

    transition: width 0.3s ease-in-out;

    cursor: pointer;
}

.ActiveDot {
    width: 100px;
}

.InactiveDot {
    width: 20px;

    opacity: 0.25;
}

@media(max-width: 950px) {
    .Section {
        padding: 40px;
    }
}