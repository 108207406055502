.Container {
    position: fixed;
    z-index : 1000;

    bottom: 20px;
    left : 20px;

    padding: 20px 40px;

    max-width : 550px;
    min-height: 100px;

    background: #C8E6C9;

    box-shadow     : 0px 1px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-radius  : 10px;

    display: block;
}

.Container a {
    color: #1B5E20;
}

.Header {
    font-family: 'Philosopher', sans-serif;
    font-weight: bold;
    font-size  : 20px;
    line-height: 22px;
    color      : #1B5E20;
}

.Content {
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size  : 13px;
    line-height: 16px;
    color      : #1B5E20;

    margin: 20px 0;
}

.Content br {
    margin-bottom: 10px;
    margin-top   : 10px;

    content: "";
    display: block;
}

.ActionContainer {
    display              : grid;
    grid-template-columns: max-content max-content;
    grid-gap             : 10px;
}

.ActionContainer>button,
.Container button {
    height : 46px;
    padding: 15px;

    border-radius: 100px;
    border       : 1px solid #1B5E20;

    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size  : 13px;
    line-height: 16px;

    cursor: pointer;
}

button.Accept {
    background: #1B5E20;
    color     : #FFFFFF;
}

button.Reject {
    background-color: transparent;
    color           : #1B5E20;
}

.Hide {
    display   : none;
    transition: display 1s ease-in-out;
}

.ItemContainer {
    display              : grid;
    grid-template-columns: max-content max-content;
    grid-gap             : 20px;

    align-items: center;
}

@media(max-width: 900px) {
    .Container {
        position: fixed;
        z-index : 1000;

        bottom: 20px;
        left  : 0;
        right : 0;
        width : 90%;
        margin: 0 auto;

        padding: 10px 20px;

        max-width : unset;
        min-height: unset;
    }
}

@media(max-width: 900px) {
    .Container {
        width : 95%;
        bottom: 10px;
    }
}