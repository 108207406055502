@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Philosopher:wght@700&display=swap');

* {
  margin    : 0;
  padding   : 0;
  box-sizing: border-box;
  border    : none;

  font-family    : 'Montserrat';
  font-style     : normal;
  text-decoration: none;

  scroll-behavior: smooth;
}

#root {
  padding-top   : env(safe-area-inset-top);
  padding-left  : env(safe-area-inset-left);
  padding-bottom: env(safe-area-inset-bottom);
  padding-right : env(safe-area-inset-right);
  overflow-x    : hidden;

  position: relative;
  z-index: 1;
}

#social-modal {
  position: fixed;
  right   : 0;
  bottom  : 0;

  z-index: 2;
}

html {
  width : 100%;
  height: 100%;
}

body {
  width: 100%;
}

.markdown p,
.markdown ol,
.markdown ul {
  margin-bottom: 15px;
}

.markdown p:last-of-type {
  margin-bottom: 0;
}

.wide-content {
  max-width: 1080px;
}

@media(max-width: 1200px) {
  .wide-content {
    max-width     : unset;
    margin        : 80px 40px 0;
    padding-bottom: 80px;
  }
}