.Footer {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;

    background: #F4FAF4;
}

.SectionContact {
    background-image: url("../../../../assets/intro_bg.png");
    background-size : cover;
    background-color: #061708;

    color: #FFFFFF;
}

.ContactContent {
    display: flex;

    width    : 90%;
    max-width: 1360px;

    margin : 0 auto;
    padding: 80px 0;
}

.ContactContent>*,
.SectionLinks>* {
    flex: 1 1 0;
}

.SectionLinks {
    display       : flex;
    flex-direction: row-reverse;
    align-items   : center;

    /* background: #F4FAF4; */
    color: #1B5E20;

    padding: 20px 40px;

    max-width   : calc(1360px + 80px);
    margin-left : auto;
    margin-right: auto;

}

@media(max-width: 1050px) {
    .SectionLinks {
        flex-direction: column;
        align-items   : flex-start;

        padding: 40px 30px;
    }

    .ContactContent {
        width  : 100%;
        padding: 80px 40px 60px;

        flex-direction : column;
        align-items    : flex-start;
        justify-content: flex-start;
    }
}