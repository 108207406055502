.Header,
.Content {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;

    color: #1E1E1E;
}

.Header {
    font-size  : 24px;
    line-height: 29px;

    font-weight: bold;

    margin-bottom: 30px;
}

.Content {
    font-size  : 24px;
    line-height: 29px;

    margin-bottom : 30px;
    padding-bottom: 30px;
}

.Content h2 {
    font-weight: 700;
    font-size  : 18px;
    line-height: 22px;

    color: #1E1E1E;

    margin: 30px 0;
}

.Content h3 {
    font-weight: 600;
    font-size  : 14px;
    line-height: 20px;

    margin-bottom: 20px;
}

.Content ul,
.Content ol {
    padding-left: 1em;
}

.Content ul li::marker {
    content: " • ";
}

.PeopleContainer {
    display  : flex;
    flex-wrap: wrap;
}

.PeopleContainer>* {
    width: calc(100% / 3);
}


@media(max-width: 900px) {
    .Content {
        font-size  : 18px;
        line-height: 22px;

        margin-bottom : 30px;
        padding-bottom: 0;
    }

    .PeopleContainer {
        flex-direction: column;
    }

    .PeopleContainer>* {
        width : 100%;
        margin: 10px 0;
        height: 308px;
    }
}