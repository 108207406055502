.Header,
.Content {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;

    color: #1E1E1E;
}

.HeaderContent {
    display        : flex;
    align-items    : flex-start;
    justify-content: space-between;
}

.Header {
    font-size  : 24px;
    line-height: 29px;

    margin-bottom: 30px;

    width: 100%;

    border-right: 1px solid rgba(27, 94, 32, 0.1);
}

.Content {
    font-size  : 14px;
    line-height: 20px;
}

.Content h2 {
    font-weight: 700;
    font-size  : 18px;
    line-height: 22px;

    color: #1E1E1E;

    margin: 30px 0;
}

.Content h3 {
    font-weight: 600;
    font-size  : 14px;
    line-height: 20px;

    margin-bottom: 20px;
}

.Content ul,
.Content ol {
    padding-left: 1em;
}

.Content ul li::marker {
    content: " • ";
}

@media(max-width: 900px) {
    .HeaderContent {
        flex-direction: column;
    }

    .Header {
        margin-bottom: 0px;

        border-right: none;
    }

    .Content {
        margin-top: 20px;
    }
}