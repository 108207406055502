.Container {
    display    : flex;
    align-items: center;

}

.CopyRight {
    display: inline-block;

    font-size  : 10px;
    line-height: 12px;

    margin-right: 40px;

    padding: 15px 0;

}

.List {
    list-style: none;

    display       : flex;
    /* flex-direction: column; */
}


.Link,
.ActiveLink {
    color: inherit;

    display: inline-block;

    font-weight: 600;
    font-size  : 10px;
    line-height: 12px;

    padding: 10px 0;

    margin-right: 10px;
}

.Link:hover {
    text-decoration: underline;
}

@media(max-width: 1050px) {
    .Container {
        flex-direction: column;
        align-items   : flex-start;
    }

    .CopyRight {
        padding: 10px 0;

        margin-bottom: 10px;

        font-size  : 12px;
        line-height: 15px;
    }

    .List {
        flex-direction: column;
    }

    .List>* {
        /* padding      : 10px 0; */
        /* margin-bottom: 5px; */
    }

    .Link,
    .ActiveLink {
        font-size  : 12px;
        line-height: 15px;
    }
}