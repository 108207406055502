.Section {
    background: #E8F5E9;
    color     : #1B5E20;

    padding: 80px 160px;
}

.Section>* {
    max-width   : 1360px;
    margin-left : auto;
    margin-right: auto;
}

.Header {
    font-weight: 400;
    font-size  : 24px;
    line-height: 29px;

    text-align: center;

    margin-bottom: 40px;
}

@media(max-width: 850px) {
    .Section {
        padding: 80px 20px;
    }
}