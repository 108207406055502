.MobileContainer {
    position: fixed;
    top     : 0;
    left    : -200vw;
    z-index : 100;

    width : 100%;
    height: 100vh;

    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);

    background-image: url("../../../../../assets/intro_bg.png");
    background-size : cover;
    background-color: #061708;

    padding: 15px 20px;

    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : flex-start;

    transition: left 1s ease-in-out
}

.MobileContainer.Open {
    left: 0;
}

.CloseIcon {
    position: absolute;
    top     : 15px;
    left    : 20px;

}

.MobileContainer ul {
    list-style: none;

    display       : flex;
    flex-direction: column;

    width: 100%;
}

.MobileContainer>ul>li {
    margin-bottom: 20px;

    max-width: 300px;
    width    : 100%;
}

.MobileContainer>ul>li:last-of-type {
    margin-bottom: 0;
}

.ExternalList {
    margin-top: 20px;
}

.ExternalLink {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
    font-size  : 14px;
    line-height: 17px;

    color: #5A8F5D;
}

.Button {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 600;
    font-size  : 14px;
    line-height: 17px;

    display: inline-block;

    padding: 10px 15px;

    background   : #1B5E20;
    border-radius: 100px;

    max-width: fit-content !important;
}

.Button a {
    color: #E8F5E9;
}

.Button a:hover {
    text-decoration: none;
}

.Button:hover {
    background: #479c4d;
}