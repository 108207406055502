.Backdrop {
    position: fixed;
    top     : 0;
    left    : 0;
    z-index : 1000;

    height: 100vh;
    width : 100vw;

    background: rgba(0, 0, 0, 0.8);

    display        : flex;
    align-items    : center;
    justify-content: center;

    overflow-y: hidden;
}

.Modal {
    width : clamp(50%, 700px, 85%);
    height: max-content;

    display       : flex;
    flex-direction: column;
    align-items   : center;

    background: #F4FAF4;

    border-radius: 5px;
}

.Button {
    display: none;
}

@media(max-width: 900px) {
    .Button {
        display: block;
        
        background-color: transparent;
        border          : none;
        color           : #5A8F5D;

        font-weight: bold;
        font-size  : 14px;
        line-height: 20px;

        margin: 10px 0 40px 0;
    }
}