.Container {
    background-color: #E8F5E9;
}

.SectionHeader {
    background: #1B5E20;

    padding: 20px 20px;

    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
    font-size  : 24px;
    line-height: 29px;

    color: #F4FAF4;
}

.SectionHeader>* {
    max-width   : 1360px;
    margin-left : auto;
    margin-right: auto;
}

.SectionHeader h1,
.SectionHeader h2,
.SectionHeader h3 {
    margin-top: 10px;

    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
    font-size  : 24px;
    line-height: 29px;

    color: #F4FAF4;
}

/* main content */
.SectionContainer {
    padding: 80px 40px;
}

.SectionContainer>article {
    max-width: 1080px;
    margin   : 0 auto;
}

.TextContainer {
    margin-bottom: 40px;
}

.Header {
    font-weight: 400;
    font-size  : 24px;
    line-height: 29px;

    margin-bottom: 40px;
}

.Content {
    margin-bottom: 30px;

    font-weight: 400;
    font-size  : 14px;
    line-height: 20px;
}