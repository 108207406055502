.Item {
    text-align: center;
}

.Header {
    margin-bottom: 20px;
}

.Header * {
    font-family   : "Philosopher";
    font-size     : 4rem;
    font-weight   : 400;
    line-height   : 100%;
    letter-spacing: 0em;
    text-align    : center;

}

.Text * {
    font-family   : Montserrat;
    font-size     : 2rem;
    font-weight   : 300;
    line-height   : 120%;
    letter-spacing: 0em;
    text-align    : center;
}

.Button {
    display: inline-block;

    background   : #1B5E20;
    border-radius: 100px;

    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 600;
    font-size  : 13px;
    line-height: 16px;

    color: #E8F5E9;

    padding: 10px 15px;

    margin-bottom: 0;
    margin-top   : 20px;
}

@media(max-width: 950px) {
    .Header * {
        font-size: 3rem;
    }

    .Text * {
        font-family   : Montserrat;
        font-size     : 1.5rem;
        font-weight   : 300;
        line-height   : 120%;
        letter-spacing: 0em;
        text-align    : center;
    }
}

@media(max-width: 600px) {
    .Header * {
        font-size: 2rem;
    }

    .Text * {
        font-family   : Montserrat;
        font-size     : 1.2rem;
        font-weight   : 300;
        line-height   : 120%;
        letter-spacing: 0em;
        text-align    : center;
    }
}