.List {
    list-style: none;
    display   : flex;
}

.List>li {
    margin-right: 10px;

    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
    font-size  : 12px;
    line-height: 15px;

    color: #F4FAF4;

    opacity: 0.5;
}

.List>li:last-of-type {
    font-weight: 600;
}

.List a {
    color: inherit;
}

.List a:hover {
    text-decoration: underline;
}

.Icon {
    margin-right: 10px;
}