.Section {
    background-image: url("../../../../assets/intro_bg.png");
    background-size : cover;
    background-color: #F4FAF4;

    text-align: center;

    color: #1B5E20;

    padding: 80px 20px;

    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
}

.Content {
    max-width: 522px;
    margin   : 0 auto;

}

.Content>* {
    margin-bottom: 40px;
}

.Img {
    max-width: 85px;
}

.Header {
    font-size  : 24px;
    line-height: 29px;

    margin-top: 0;
}

.Text {
    font-size  : 14px;
    line-height: 17px;

    margin-top: 0;
}

.Button {
    display: inline-block;

    font-weight: 600;
    font-size  : 14px;
    line-height: 17px;

    color: inherit;

    text-decoration: none;

    border       : 1px solid #1B5E20;
    border-radius: 100px;

    padding      : 10px 40px;
    margin-bottom: 0;
}

@media(max-width: 850px) {
    .Header {
        font-size  : 16px;
        line-height: 20px;
    }
}