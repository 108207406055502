.Modal {
    position: relative;

    bottom      : 20px;
    margin-right: 20px;

    width: 384px;

    padding: 20px;

    border-radius: 10px;

    z-index: 2;

    box-shadow: 0px 5px 25px 0px #1B5E2040;

    font-family: 'Montserrat', sans-serif;

    background-color: #FFFFFF;
}

.Header {
    font-size  : 18px;
    font-weight: 600;
    line-height: 21.94px;

    text-align: left;

    color: #1B5E20;

    padding-right: 55px;
    /*close button space*/
    margin-bottom: 20px;
}

.Content {
    font-size  : 14px;
    font-weight: 400;
    line-height: 17.07px;
    text-align : left;

    color: #1B5E20;

    margin-bottom: 20px;
}


.Close {
    position: absolute;
    z-index : 1;

    right: 20px;
    top  : 20px;

    font-size  : 12px;
    font-weight: 600;
    line-height: 14.63px;

    color: #1B5E20;

    background-color: transparent;

    cursor: pointer;

    max-width         : 55px;
    display           : -webkit-box;
    line-clamp        : 1;
    -webkit-box-orient: vertical;
    overflow          : hidden;

    text-overflow: ellipsis;
}

.LinkButton {
    width  : 200px;
    height : 32px;
    padding: 7px;

    border-radius: 16px;

    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;

    cursor: pointer;

    font-family: "SF Pro Text", Helvetica, sans-serif;
    font-size  : 14px;
    font-weight: 600;
    line-height: 17.07px;
    text-align : center;

    /* box-shadow: 0px 5px 25px 0px #1B5E2040; */

    outline        : none;
    text-decoration: none !important;
}

.LinkButton>img {
    margin-right: 10px;

    max-height: 17.07px;
    max-width : 17.07px;
}

.LinkButton>img[src=""] {
    visibility: hidden;
}

.LinkButton:hover {
    filter: saturate(65%);
}

.ActionContainer {
    width: max-content;

    display       : flex;
    flex-direction: column;
    align-items   : stretch;
}

.ActionContainer>* {
    margin-bottom: 10px;
}

@media(max-width: 900px) {
    .Modal {
        width : 90%;
        bottom: 10px;
        right : 5%;
        left  : 5%;

        margin-right: 0px;
    }

}