.Container {
    /* padding: 80px 40px; */
}

.Container>article {
    max-width: 1080px;
    margin   : 0 auto;
}

.TextContainer {
    margin-bottom: 40px;
}

.Header {
    font-weight: 400;
    font-size  : 24px;
    line-height: 29px;

    margin-bottom: 40px;
}

.Content {
    margin-bottom: 30px;

    font-weight: 400;
    font-size  : 14px;
    line-height: 20px;
}