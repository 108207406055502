.Item {
    display: flex;
    justify-content: space-between;

    font-family: 'Montserrat';
    font-style : normal;

    padding-bottom: 40px;
    margin-bottom : 10px;

    --space_1: 40px;
}

.ItemContent {
    margin-right: var(--space_1);
}

.Title {
    font-weight: 600;
    font-size  : 32px;
    line-height: 40px;

    color: #1B5E20;

    margin-bottom: var(--space_1);

    /* display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden; */
}

.Title:hover {
    text-decoration: underline;
}

.Desc {
    font-weight: 400;
    font-size  : 14px;
    line-height: 20px;

    color: #1E1E1E;

    display           : -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow          : hidden;

    text-overflow: ellipsis;
}

.ImgContent {
    background-color: #1B5E20;

    border-radius: 10px;
}

.ImgContent a {
    display: inline-block;
}

.ImgContent img {
    object-fit: cover;
}

.ImgContent img,
.ImgContent a,
.ImgContent {
    width : 200px;
    height: 200px;

    border-radius: 10px;
}

.ImgContent img:hover {
    opacity: 0.4;
}

.Date {
    display: inline-block;
    margin-top: 10px;

    font-weight: 400;
    font-size  : 14px;
    line-height: 20px;

    color: rgba(30, 30, 30, 0.5);
}

@media(max-width: 800px) {
    .Item {
        flex-direction: column-reverse;

        --space_1: 20px;
    }

    .ItemContent {
        margin-right: 0;
    }

    .ImgContent {
        margin-bottom: var(--space_1);
    }

    .ImgContent img,
    .ImgContent a,
    .ImgContent {
        width : 100%;
        height: 200px;

        border-radius: 10px;
    }

    .Title {
        font-size  : 24px;
        line-height: 29px;
    }
}