.AccordionItem {
	padding      : 20px;
	border-radius: 5px;
	box-shadow   : 0px 1px 1px rgba(0, 0, 0, 0.05);

	background: #F4FAF4;
	color     : #1B5E20;

	margin-bottom: 20px;

	position: relative;
	z-index : 1;
	cursor  : pointer;

	-webkit-tap-highlight-color: transparent;
}

.AccordionItem:focus {
	outline: none;
}

.Question {
	position     : relative;
	padding-right: 30px;

	transition: all 0.4s ease;

	font-weight: 400;
	font-size  : 24px;
	line-height: 29px;
}

.Question::after {
	content  : '';
	position : absolute;
	top      : 50%;
	right    : 0px;
	transform: translateY(-50%);
	width    : 16px;
	height   : 16px;

	background-image   : url('../../../../assets/arrow_closed.svg');
	background-position: center;
	background-size    : contain;
	background-repeat  : no-repeat;

	transition: all 0.4s ease-out;
}

.Answer {
	opacity   : 0;
	max-height: 0;
	overflow-y: hidden;

	transition: all 0.4s ease-out;

	font-weight   : 400;
	font-size     : 14px;
	line-height   : 17px;

	color: #1E1E1E;
}

.Open .Question {
	margin-bottom: 20px;
}

.Open .Question::after {
	transform       : translateY(-50%) rotate(180deg);
	background-image: url('../../../../assets/arrow_open.svg');
}

.Open .Answer {
	max-height: 200px;
	opacity   : 1;
	overflow-y: auto;
}