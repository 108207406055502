#main-section .alice-carousel__dots {
    margin: 0px;

    position: absolute;
    bottom  : calc(35% - 40px);
    left    : 7.5%;
}

@media(max-width: 950px) {
    #main-section .alice-carousel__dots {
        position: relative;

        margin-top: 40px;
    }
}