.Wrapper {
    background-color: #E8F5E9;

    padding: 80px 40px;
}

.Container {
    max-width: 1080px;
    margin   : 0 auto;

    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;

    color: #1E1E1E;
}

.Header {
    font-size  : 24px;
    line-height: 29px;
    font-weight: 400;

    margin-bottom: 20px;
}

.Content {
    font-size  : 14px;
    line-height: 20px;
}

.Content h2 {
    font-weight: 700;
    font-size  : 18px;
    line-height: 22px;

    margin: 30px 0;
}

.Content h3 {
    font-weight: 600;
    font-size  : 14px;
    line-height: 20px;

    margin-bottom: 20px;
}

.Content ul,
.Content ol {
    padding-left: 1em;
}

.Content ul li::marker {
    content: " • ";
}

.Content img {
    width     : 100%;
    max-height: 50vh;

    object-fit: cover;

    border-radius: 10px;

    margin-bottom: 25px;
}