.Section {
    background-color: #1B5E20;
    color           : white;

    /* text-align: center; */

    padding: 40px 40px;

}

.Content {
    max-width: 1360px;

    margin: 0 auto;
}

.Content>* {
    margin-bottom: 40px;
}

.Header {
    font-weight: 400;
    font-size  : 14px;
    line-height: 17px;

    text-align: left;
}

.ButtonContent {
    text-align: center;

    margin-bottom: 0;
}

.Button {
    display: inline-block;

    font-weight: 600;
    font-size  : 14px;
    line-height: 17px;

    padding: 10px 40px;

    border       : 1px solid #F4FAF4;
    border-radius: 100px;
    margin-bottom: 0;

    text-decoration: none;
    color          : white;
}

.Dot {
    display: inline-block;

    height      : 3px;
    margin-right: 10px;

    border-radius: 100px;

    background: #5A8F5D;

    transition: width 0.3s ease-in-out;

    cursor: pointer;
}

.ActiveDot {
    width: 100px;
}

.InactiveDot {
    width: 20px;

    opacity: 0.25;
}

.ActionContainer {
    display: none;
}

@media(max-width: 750px) {
    .ActiveDot {
        width: 20px;

        background-color: white;
    }

    .ActionContainer {
        display : block;
        position: absolute;

        display        : flex;
        justify-content: space-between;

        width : 100%;
        left  : 0;
        bottom: -50px;
    }

    .Content {
        position: relative;
    }

    .ActionContainer>*:first-child {}

    .ActionContainer>*:last-child {}

}

@media(max-width: 450px) {
    .ActionContainer, .Header  {
        padding-left: 10px;
        padding-right: 10px;
    }

    .Section {
        padding-left : 30px;
        padding-right: 30px;
    }
}