.Container {
    background-color: #E8F5E9;

    font-family: 'Montserrat';
    font-style : normal;
}

.SectionHeader {
    background: #1B5E20;

    padding: 20px 20px;

    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
    font-size  : 24px;
    line-height: 29px;

    color: #F4FAF4;
}

.SectionHeader>* {
    max-width   : 1360px;
    margin-left : auto;
    margin-right: auto;
}

.SectionHeader h1,
.SectionHeader h2,
.SectionHeader h3 {
    margin-top: 10px;

    font-weight: 400;
    font-size  : 24px;
    line-height: 29px;

    color: #F4FAF4;
}

.Header {
    display        : flex;
    align-items    : flex-start;
    justify-content: space-between;

    margin-bottom: 10px;
}

.SectionContent {
    margin        : 80px auto 0;
    padding-bottom: 80px;

    max-width: 800px;

    color      : #1E1E1E;
    font-size  : 14px;
    line-height: 20px;
}

.Title {
    font-weight: 400;
    font-size  : 24px;
    line-height: 29px;

    color: #1E1E1E;

    align-self: center;

    width: 100%;

    border-right: 1px solid rgba(27, 94, 32, 0.1);
}

.Date {
    display      : block;
    margin-top   : 10px;
    margin-bottom: 10px;

    font-weight: 400;
    font-size  : 14px;
    line-height: 20px;

    color: rgba(30, 30, 30, 0.5);
}

@media(max-width: 900px) {
    .SectionContent {
        max-width     : unset;
        margin        : 80px 40px 0;
        padding-bottom: 80px;
    }

    .Header {
        flex-direction: column;
    }

    .Title {
        align-self: flex-start;

        border-right: none;
    }
}