.Container {
    display    : flex;
    align-items: flex-start;
}

.Img {
    padding     : 10px;
    margin-right: 40px;

    max-width: 86px;
}

.TextContainer {
    padding: 10px;
}

.Header {
    font-size  : 24px;
    line-height: 29px;

    margin-bottom: 20px;
}

.Text {
    font-size  : 14px;
    line-height: 17px;

    color: rgba(244, 250, 244, 0.5);
}

@media(max-width: 1050px) {
    .Container {
        flex-direction : column;
        align-items    : center;
        justify-content: flex-start;

        width: 100%;

        margin-bottom: 40px;
    }

    .Img {
        margin-right: 0px;
        margin-bottom: 40px;

        width    : 23%;
        max-width: 86px;
        min-width: 20px;
    }
}