#news-media .alice-carousel__dots {
    margin-top: 40px;
}

@media(max-width: 750px) {
    #news-media .alice-carousel__dots {
        margin-top: 20px;

        display   : flex;
        overflow-y: scroll;

        margin-left : auto;
        margin-right: auto;

        width: calc(20px * 5 + 10px * 4);
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    #news-media .alice-carousel__dots::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    #news-media .alice-carousel__dots {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width   : none;
        /* Firefox */
    }   
}