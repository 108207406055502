.ActiveLink,
.Link {
    /* display: inline-block; */

    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
    font-size  : 24px;
    line-height: 29px;

    color: #FFFFFF;

    padding: 0;

    display        : flex;
    justify-content: space-between;
    align-items    : center;
}

.ActiveLink {
    font-weight: 600;

    text-decoration: underline;
}

.Link:focus {
    text-decoration: underline;
}

.DownButton {
    margin-left: 10px;

    border      : solid #1B5E20;
    border-width: 0 1.5px 1.5px 0;
    display     : inline-block;
    padding     : 3px;

    transform        : rotate(45deg);
    -webkit-transform: rotate(45deg);

    margin-bottom: 3px;

    transition: transform 0.5s linear;
}

.DownButton.Open {
    transform        : rotate(225deg);
    -webkit-transform: rotate(225deg);

}

.SubNav {
    width     : max-content;
    max-height: 0;
    overflow  : hidden;
    padding   : 0px;

    display       : flex;
    flex-direction: column;

    position: relative;
    top     : 0px;
    left    : 0;
    z-index : 0;

    background: transparent;
    box-shadow: none;

    transition: max-height 0.3s linear;
}

.SubNav.Open {
    max-height: 50vh;
    padding   : 10px 0;

}

.SubNav li {
    margin-bottom: 10px;
}

.SubNav li:last-of-type {
    margin-bottom: 0;
}

.SubNav .Link,
.SubNav .ActiveLink {
    font-family: 'Montserrat';
    font-style : normal;
    font-weight: 400;
    font-size  : 14px;
    line-height: 17px;

    color: #FFFFFF;
}

.SubNav .ActiveLink {
    font-weight: 600;
}