.Form {
    display       : flex;
    flex-direction: column;
}

.Consent {
    display: flex;

    margin: 20px 0;

    cursor: pointer;
}

.ConsentHeader {
    font-weight: 400;
    font-size  : 14px;
    line-height: 17px;

    color: rgba(255, 255, 255, 0.5);

    margin-bottom: 15px;
}

.ConsentText {
    margin-left: 15px;

    font-weight: 400;
    font-size  : 14px;
    line-height: 17px;

    color: rgba(255, 255, 255, 0.5);
}

.Form input {
    font-weight: 400;
    font-size  : 14px;
    line-height: 17px;

    padding      : 15px 10px;
    margin-bottom: 10px;

    border-bottom   : 1px solid rgba(255, 255, 255, 0.2);
    background-color: transparent;
    outline         : none;
    color           : white;
}

.Form input:focus {
    border-bottom-color: white;
}

.Form input.Invalid {
    border-bottom-color: rgb(176, 9, 9);
}

.Form input.Valid {
    border-bottom-color: white;
}

.Button {
    align-self: flex-start;

    font-weight: 600;
    font-size  : 14px;
    line-height: 17px;

    color     : #E8F5E9;
    background: #1B5E20;

    border-radius: 100px;

    filter: drop-shadow(0px 5px 25px rgba(27, 94, 32, 0.25));

    padding: 10px 15px;
}

.Button:hover {
    background-color: #ffffff29;
}

.Form.Valid button {
    cursor: pointer;
}

.Form.Invalid button {
    cursor: not-allowed;
}

.Message {
    min-height: 16px;

    margin-bottom: 0;
    margin-top   : 20px;

    font-weight: 400;
    font-size  : 14px;
    line-height: 16px;
}

@media(max-width: 800px) {
    /* .Form {
        width: 100%;

        max-width: 400px;
    } */
}